import React from "react"
import { CartProvider } from './src/context/'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from './src/theme'
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => (
    <CartProvider
        successUrl='http://localhost:3000/success'
        cancelUrl='http://localhost:3000'
        currency='USD'
        allowedCountries={['US', 'CA']}
        billingAddressCollection
    >
        <ThemeProvider theme={theme}>
            {element}
        </ThemeProvider>
    </CartProvider>
)
