import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
    palette: {
        secondary: {
            // This is green.A700 as hex.
            main: '#DE1E35',
        }
    }
});

export default theme;
